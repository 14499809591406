import React from "react";
import SEO from "../common/SEO";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import { useState } from "react";
import { AlertCircle, Check } from "lucide-react";

const Register = () => {
  const [formData, setFormData] = useState({
    name: "",
    regNo: "",
    branch: "",
    section: "",
    email: "",
    phone: "",
    team: "",
    whyJoin: "",
    portfolio: "",
  });
  
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [submittedData, setSubmittedData] = useState(null);
  const [submitError, setSubmitError] = useState("");

  const GOOGLE_SCRIPT_URL = "https://script.google.com/macros/s/AKfycbzZy1Dv-58x7eGe6QhxX3CHN2Dywt-Edk9kwPdzZOm-5Y3htPvAJxbKX5FsqzgRB4a0/exec";

  const branches = [
    "CSE-Core",
    "CSE-AIML",
    "CSE-Data Science",
    "CSE-Cyber",
    "CSE-Cloud",
    "CSE-BS",
  ];

  const sections = Array.from({ length: 26 }, (_, i) =>
    String.fromCharCode(65 + i)
  );

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name.trim()) newErrors.name = "Name is required";
    if (!formData.regNo.trim()) newErrors.regNo = "Registration number is required";
    if (!formData.branch) newErrors.branch = "Branch is required";
    if (!formData.section) newErrors.section = "Section is required";
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Invalid email format";
    }
    if (!formData.phone.trim()) {
      newErrors.phone = "Phone number is required";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Phone number must be 10 digits";
    }
    if (!formData.team) newErrors.team = "Team selection is required";
    if (!formData.whyJoin.trim()) newErrors.whyJoin = "Please tell us why you want to join";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      setSubmitError("");

      try {
        // Convert form data to URL-encoded string
        const formDataArray = Object.keys(formData).map(key => 
          `${encodeURIComponent(key)}=${encodeURIComponent(formData[key])}`
        );
        const formDataString = formDataArray.join('&');

        // Send data to Google Script
        const response = await fetch(GOOGLE_SCRIPT_URL, {
          method: 'POST',
          mode: 'no-cors',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: formDataString
        });

        // Since we're using no-cors, we can't actually check the response
        // We'll assume success if no error was thrown
        setSubmittedData(formData);
        setSubmitted(true);
      } catch (error) {
        console.error("Submission error:", error);
        setSubmitError("Failed to submit form. Please try again.");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: '',
      }));
    }
  };

  return (
    <>
      <SEO title="Register" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BreadCrumbOne title="Register" page="Register" />
        <div className="section section-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="iste-registration-wrapper">
                  {submitted ? (
                    <div className="iste-registration-success">
                      <div className="iste-registration-success__icon">
                        <Check />
                      </div>
                      <h2 className="iste-registration-success__title">
                        Thank You!
                      </h2>
                      <p className="iste-registration-success__message">
                        Your registration has been successfully submitted.
                      </p>
                    </div>
                  ) : (
                    <form onSubmit={handleSubmit} className="iste-registration-form">
                      <div className="iste-registration-form__header">
                        <h2 className="iste-registration-form__title">
                          Registration Form
                        </h2>
                      </div>

                      {submitError && (
                        <div className="iste-registration-form__submit-error">
                          <AlertCircle />
                          <span>{submitError}</span>
                        </div>
                      )}

                      <div className="iste-registration-form__field">
                        <label className="iste-registration-form__label">
                          Name *
                        </label>
                        <input
                          type="text"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          className={`iste-registration-form__input ${
                            errors.name ? "iste-registration-form__input--error" : ""
                          }`}
                        />
                        {errors.name && (
                          <div className="iste-registration-form__error">
                            <AlertCircle />
                            {errors.name}
                          </div>
                        )}
                      </div>

                      <div className="iste-registration-form__field">
                        <label className="iste-registration-form__label">
                          Registration Number *
                        </label>
                        <input
                          type="text"
                          name="regNo"
                          value={formData.regNo}
                          onChange={handleChange}
                          className={`iste-registration-form__input ${
                            errors.regNo ? "iste-registration-form__input--error" : ""
                          }`}
                        />
                        {errors.regNo && (
                          <div className="iste-registration-form__error">
                            <AlertCircle />
                            {errors.regNo}
                          </div>
                        )}
                      </div>

                      <div className="iste-registration-form__field">
                        <label className="iste-registration-form__label">
                          Branch & Section *
                        </label>
                        <div className="iste-registration-form__branch-section">
                          <div className="branch-select">
                            <select
                              name="branch"
                              value={formData.branch}
                              onChange={handleChange}
                              className={`iste-registration-form__select ${
                                errors.branch ? "iste-registration-form__select--error" : ""
                              }`}
                            >
                              <option value="">Select Branch</option>
                              {branches.map((branch) => (
                                <option key={branch} value={branch}>
                                  {branch}
                                </option>
                              ))}
                            </select>
                            {errors.branch && (
                              <div className="iste-registration-form__error">
                                <AlertCircle />
                                {errors.branch}
                              </div>
                            )}
                          </div>
                          <div className="section-select">
                            <select
                              name="section"
                              value={formData.section}
                              onChange={handleChange}
                              className={`iste-registration-form__select ${
                                errors.section ? "iste-registration-form__select--error" : ""
                              }`}
                            >
                              <option value="">Select Section</option>
                              {sections.map((section) => (
                                <option key={section} value={section}>
                                  {section}
                                </option>
                              ))}
                            </select>
                            {errors.section && (
                              <div className="iste-registration-form__error">
                                <AlertCircle />
                                {errors.section}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="iste-registration-form__field">
                        <label className="iste-registration-form__label">
                          Email *
                        </label>
                        <input
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          className={`iste-registration-form__input ${
                            errors.email ? "iste-registration-form__input--error" : ""
                          }`}
                        />
                        {errors.email && (
                          <div className="iste-registration-form__error">
                            <AlertCircle />
                            {errors.email}
                          </div>
                        )}
                      </div>

                      <div className="iste-registration-form__field">
                        <label className="iste-registration-form__label">
                          Phone Number *
                        </label>
                        <input
                          type="tel"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                          className={`iste-registration-form__input ${
                            errors.phone ? "iste-registration-form__input--error" : ""
                          }`}
                        />
                        {errors.phone && (
                          <div className="iste-registration-form__error">
                            <AlertCircle />
                            {errors.phone}
                          </div>
                        )}
                      </div>

                      <div className="iste-registration-form__field">
                        <label className="iste-registration-form__label">
                          Team to Apply *
                        </label>
                        <select
                          name="team"
                          value={formData.team}
                          onChange={handleChange}
                          className={`iste-registration-form__select ${
                            errors.team ? "iste-registration-form__select--error" : ""
                          }`}
                        >
                          <option value="">Select a team</option>
                          <option value="Technical Team">Technical Team</option>
                          <option value="Creative Team">Creative Team</option>
                          <option value="Publicity Team">Publicity Team</option>
                          <option value="Content Team">Content Writing Team</option>
                          <option value="Social Team">Social Media Team</option>
                          <option value="PR Team">PR Team</option>
                        </select>
                        {errors.team && (
                          <div className="iste-registration-form__error">
                            <AlertCircle />
                            {errors.team}
                          </div>
                        )}
                      </div>

                      <div className="iste-registration-form__field">
                        <label className="iste-registration-form__label">
                          Why do you want to join ISTE? *
                        </label>
                        <textarea
                          name="whyJoin"
                          value={formData.whyJoin}
                          onChange={handleChange}
                          className={`iste-registration-form__textarea ${
                            errors.whyJoin ? "iste-registration-form__textarea--error" : ""
                          }`}
                          placeholder="Tell us why you want to join ISTE..."
                        />
                        {errors.whyJoin && (
                          <div className="iste-registration-form__error">
                            <AlertCircle />
                            {errors.whyJoin}
                          </div>
                        )}
                      </div>

                      <div className="iste-registration-form__field">
                        <label className="iste-registration-form__label">
                          Share your work (Optional)
                        </label>
                        <textarea
                          name="portfolio"
                          value={formData.portfolio}
                          onChange={handleChange}
                          className="iste-registration-form__textarea"
                          placeholder="Share links to your projects, portfolio, or any relevant work..."
                        />
                      </div>

                      <button
                        type="submit"
                        disabled={loading}
                        className="axil-btn btn-fill-primary btn-fluid btn-primary"
                      >
                        {loading ? "Submitting..." : "Submit Registration"}
                      </button>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
          <ul className="list-unstyled shape-group-12">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/circle-3.png"}
                alt="Circle"
              />
            </li>
          </ul>
        </div>
        <FooterOne parentClass="pt--0 pt_lg--0 pt_md--10 pt_sm--60" />
      </main>
    </>
  );
};

export default Register;
